import React, { useState, useEffect } from "react"
import { useParams } from "@reach/router"

// components
import SEO from "../components/SEO"
import Hero from "../components/Hero"

import FamiliesGrid from "../components/FamiliesGrid"
import Partners from "../components/Partners"
import EmailSection from "../components/EmailSection"
import Subscribe from "../components/Subscribe"
import Corporates from "../components/Corporates"
import NewsCarousel from "../components/NewsCarousel"
// import GivingCards from "../components/GivingCards"

const SponsorHome = args => {
  // const [givingCardOpen, setGivingCardOpen] = useState(true)

  // Hacky way to check if the pageContext is a sponsor
  const sponsor = args.pageContext.hasOwnProperty("filter_criteria_needs_type")
    ? args.pageContext
    : null
  const organization = args.pageContext.hasOwnProperty("organization_id")
    ? args.pageContext
    : null

  return (
    <>
      <SEO />
      <Hero sponsor={sponsor} />
      {/*NOTE: if we reintroduce GivingCards, it used to use the klaviyo public key to send events. All the other usages are on the backend, so we swapped them to use the private key. Either introduce two flows, or plumb this through the backend*/}
      {/*<GivingCards*/}
      {/*  givingCardOpen={givingCardOpen}*/}
      {/*  closeGivingCard={() => setGivingCardOpen(false)}*/}
      {/*/>*/}
      <FamiliesGrid sponsor={sponsor} organization={organization} />
      {/* <WaysToHelp /> */}
      {/* <Introduction />
      <HowItWorks />
      <FamiliesGrid /> */}
      <EmailSection />
      <NewsCarousel />
      {/* <ClosedCases /> */}
      <Subscribe />
    </>
  )
}

export default SponsorHome
